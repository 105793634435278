import React, { useEffect, useRef } from 'react'
import FocusTrap from '../../hooks/useFocusTrap'

interface Props {
  content: string
  isActive: boolean
  toggleModal: () => void
  modalId: string
  ariaLabelledBy: string
  labelId: string
}

const Modal: React.FC<Props> = (props: Props, ref) => {
  useEffect(() => {
    if (props.isActive) {
      document.body.classList.add('modal-active')
    } else {
      document.body.classList.remove('modal-active')
    }
  }, [props.isActive])

  const innerFocusRef = useRef<HTMLButtonElement>()
  useEffect(() => innerFocusRef.current && innerFocusRef.current.focus())

  return (
    <FocusTrap>
      <div
        id={props.modalId}
        aria-modal
        aria-labelledby={props.ariaLabelledBy}
        tabIndex={-1}
        role="dialog"
        className={[
          'modal fixed top-0 left-0 z-50 flex h-full w-full items-center justify-center',
          !props.isActive && 'pointer-events-none hidden',
          props.isActive && 'modal-active',
        ].join(' ')}
      >
        <div className="modal-overlay absolute h-full w-full bg-gray-900 opacity-50"></div>

        <div
          ref={ref}
          className="modal-container z-50 mx-auto w-11/12 overflow-y-auto rounded bg-white shadow-lg md:max-w-md"
        >
          <div className="modal-content py-4 px-6 text-left">
            <div className="relative flex items-center justify-between">
              <button
                className="modal-close absolute top-2 right-2 z-50 cursor-pointer"
                onClick={props.toggleModal}
                type="button"
                ref={innerFocusRef}
              >
                <span className="sr-only">Close</span>
                <svg
                  className="fill-current text-black"
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                >
                  <path d="M14.53 4.53l-1.06-1.06L9 7.94 4.53 3.47 3.47 4.53 7.94 9l-4.47 4.47 1.06 1.06L9 10.06l4.47 4.47 1.06-1.06L10.06 9z"></path>
                </svg>
              </button>
            </div>

            <div
              id={props.labelId}
              dangerouslySetInnerHTML={{
                __html: props.content as string,
              }}
            />
          </div>
        </div>
      </div>
    </FocusTrap>
  )
}

export default React.forwardRef(Modal)
